import { toast } from 'react-toastify';

interface Response {
  response?: string;
  message?: string;
  status?: number;
  data?: string;
}

export function errorHandler(response: Response): void {
  if (response?.status) {
    throwErrorStatusCode(response);
  } else if (!response.message) {
    toast.error(response);
  } else {
    toast.error(response.message);
  }
}

function throwErrorStatusCode({ data, status }: Response): void {
  switch (status) {
    case 500:
      toast.error(data);
      break;
    default:
      toast.warning(data);
  }
}
