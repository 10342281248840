import moment from 'moment';

// Identifica o tipo de código [Linha digitável, Código de Barras]
export function identificarTipoCodigo(codigo: string) {
  if (typeof codigo !== 'string')
    throw new TypeError('Insira uma string válida!');

  codigo = codigo.replace(/[^0-9]/g, '');

  if (codigo.length === 44) {
    return 'CODIGO_DE_BARRAS';
  }
  if (codigo.length === 46 || codigo.length === 47 || codigo.length === 48) {
    return 'LINHA_DIGITAVEL';
  }
  return 'TAMANHO_INCORRETO';
}

// Identifica o tipo de Boleto [Banco, Arrecadação ou algum tipo de Convênio]
export function identificarTipoBoleto(codigo: string) {
  codigo = codigo.replace(/[^0-9]/g, '');

  if (typeof codigo !== 'string')
    throw new TypeError('Insira uma string válida!');

  if (
    codigo.substring(-14) === '00000000000000' ||
    codigo.substring(5, 29) === '00000000000000'
  ) {
    return 'CARTAO_DE_CREDITO';
  }
  if (codigo.substring(0, 1) === '8') {
    if (codigo.substring(1, 2) === '1') {
      return 'ARRECADACAO_PREFEITURA';
    }
    if (codigo.substring(1, 2) === '2') {
      return 'CONVENIO_SANEAMENTO';
    }
    if (codigo.substring(1, 2) === '3') {
      return 'CONVENIO_ENERGIA_ELETRICA_E_GAS';
    }
    if (codigo.substring(1, 2) === '4') {
      return 'CONVENIO_TELECOMUNICACOES';
    }
    if (codigo.substring(1, 2) === '5') {
      return 'ARRECADACAO_ORGAOS_GOVERNAMENTAIS';
    }
    if (codigo.substring(1, 2) === '6' || codigo.substring(1, 2) === '9') {
      return 'OUTROS';
    }
    if (codigo.substring(1, 2) === '7') {
      return 'ARRECADACAO_TAXAS_DE_TRANSITO';
    }
  } else {
    return 'BANCO';
  }
}

// Identifica o terceiro campo da numeração inserida para definir como será calculado o Dígito Verificador
export function identificarReferencia(codigo: string) {
  codigo = codigo.replace(/[^0-9]/g, '');

  const referencia = codigo.substring(2, 3);
  if (typeof codigo !== 'string')
    throw new TypeError('Insira uma string válida!');
  switch (referencia) {
    case '6':
      return {
        mod: 10,
        efetivo: true,
      };
      break;
    case '7':
      return {
        mod: 10,
        efetivo: false,
      };
      break;
    case '8':
      return {
        mod: 11,
        efetivo: true,
      };
      break;
    case '9':
      return {
        mod: 11,
        efetivo: false,
      };
      break;
    default:
      break;
  }
}

/* IDENTIFICADORES DE INFORMAÇÕES PARA PREENCHIMENTO DE MODAL */
// Identifica o tipo de Aceite [Simplifcado, Boleto, Concessionária]
export function identificarTipoAceite(codigo: string, tipoCodigo: string) {
  codigo = codigo.replace(/[^0-9]/g, '');
  if (codigo.length === 44) {
    if (tipoCodigo === 'CODIGO_DE_BARRAS' && codigo.substring(0, 1) === '8') {
      return 2;
    }
    return 1;
  }
  if (codigo.length >= 45 && codigo.length <= 47) {
    return 1;
  }
  if (codigo.length === 48) {
    return 2;
  }

  return 0;
}
// Identifica Data de Vencimento
export function identificarData(codigo: string, tipoCodigo: string) {
  codigo = codigo.replace(/[^0-9]/g, '');
  const tipoBoleto = identificarTipoBoleto(codigo);

  let fatorData = '';
  const dataBoleto = new Date('1997-10-08');

  if (tipoCodigo === 'CODIGO_DE_BARRAS') {
    if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
      fatorData = codigo.substring(5, 9);
    } else {
      fatorData = '0';
    }
  } else if (tipoCodigo === 'LINHA_DIGITAVEL') {
    if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
      fatorData = codigo.substring(33, 37);
    } else {
      fatorData = '0';
    }
  }
  const dataVencimento = new Date(
    dataBoleto.getTime() + Number(fatorData) * 24 * 60 * 60 * 1000,
  );
  // dataBoleto.add(Number(fatorData), 'days');

  return dataVencimento;
}
// Identifica Código de Banco
export function itendificarBanco(codigo: string) {
  codigo = codigo.replace(/[^0-9]/g, '');
  let codigoBanco = '';
  const tipoBoleto = identificarTipoBoleto(codigo);
  if (tipoBoleto === 'BANCO') {
    codigoBanco = codigo.substring(0, 3);
  }
  return codigoBanco;
}
// Identifica o Valor
export function identificarValor(codigo: string, tipoCodigo: string) {
  const tipoBoleto = identificarTipoBoleto(codigo);

  let valorBoleto = '';
  let valorFinal: any;

  if (tipoCodigo === 'CODIGO_DE_BARRAS') {
    if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
      valorBoleto = codigo.substring(9, 19);
      valorFinal = `${valorBoleto.substring(0, 8)}.${valorBoleto.substring(
        8,
        10,
      )}`;

      let char = valorFinal.substring(1, 2);
      while (char === '0') {
        valorFinal = substringReplace(valorFinal, '', 0, 1);
        char = valorFinal.substring(1, 2);
      }
    } else {
      valorFinal = identificarValorCodBarrasArrecadacao(
        codigo,
        'CODIGO_DE_BARRAS',
      );
    }
  } else if (tipoCodigo === 'LINHA_DIGITAVEL') {
    if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
      valorBoleto = codigo.substring(37);
      valorFinal = `${valorBoleto.substring(0, 8)}.${valorBoleto.substring(
        8,
        10,
      )}`;

      let char = valorFinal.substring(1, 2);
      while (char === '0') {
        valorFinal = substringReplace(valorFinal, '', 0, 1);
        char = valorFinal.substring(1, 2);
      }
    } else {
      valorFinal = identificarValorCodBarrasArrecadacao(
        codigo,
        'LINHA_DIGITAVEL',
      );
    }
  }
  const valorFormatado = parseFloat(valorFinal)
    .toFixed(2)
    .replace('.', ',')
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  return valorFormatado;
}
// Identifica o Valor em boletos de Arrecadação
export function identificarValorCodBarrasArrecadacao(
  codigo: string,
  tipoCodigo: string,
) {
  codigo = codigo.replace(/[^0-9]/g, '');
  const isValorEfetivo = identificarReferencia(codigo)?.efetivo;

  let valorBoleto = '';
  let valorBoletoArray = [];
  let valorFinal;

  if (isValorEfetivo) {
    if (tipoCodigo === 'LINHA_DIGITAVEL') {
      valorBoleto = codigo.substring(4, 18);
      valorBoletoArray = codigo.split('');
      valorBoletoArray.splice(11, 1);
      valorBoleto = valorBoletoArray.join('');
      valorBoleto = valorBoleto.substring(4, 15);
    } else if (tipoCodigo === 'CODIGO_DE_BARRAS') {
      valorBoleto = codigo.substring(4, 15);
    }

    valorFinal = `${valorBoleto.substring(0, 9)}.${valorBoleto.substring(
      9,
      11,
    )}`;

    let char = valorFinal.substring(1, 2);
    while (char === '0') {
      valorFinal = substringReplace(valorFinal, '', 0, 1);
      char = valorFinal.substring(1, 2);
    }
  } else {
    valorFinal = 0;
  }

  return valorFinal;
}

function validarComDVConvertido(codigo: string, tipoCodigo: string): boolean {
  if (tipoCodigo === 'CODIGO_DE_BARRAS') {
    return !validarCodigoComDV(
      codBarras2LinhaDigitavel(codigo, false),
      'LINHA_DIGITAVEL',
    );
  }
  if (tipoCodigo === 'LINHA_DIGITAVEL') {
    return !validarCodigoComDV(
      linhaDigitavel2CodBarras(codigo),
      'CODIGO_DE_BARRAS',
    );
  }
  return false;
}
export function validarCodigoComDV(codigo: string, tipoCodigo: string) {
  codigo = codigo.replace(/[^0-9]/g, '');
  let tipoBoleto;

  let resultado;
  let bloco1 = '';
  let bloco2 = '';
  let bloco3 = '';
  let bloco4 = '';
  let bloco5 = '';

  if (tipoCodigo === 'LINHA_DIGITAVEL') {
    tipoBoleto = identificarTipoBoleto(codigo);
    if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
      bloco1 = codigo.substring(0, 9) + calculaMod10(codigo.substring(0, 9));
      bloco2 =
        codigo.substring(10, 20) + calculaMod10(codigo.substring(10, 20));
      bloco3 =
        codigo.substring(21, 31) + calculaMod10(codigo.substring(21, 31));
      bloco4 = codigo.substring(32, 33);
      bloco5 = codigo.substring(33);

      resultado = (bloco1 + bloco2 + bloco3 + bloco4 + bloco5).toString();
    } else {
      const identificacaoValorRealOuReferencia = identificarReferencia(codigo);

      if (identificacaoValorRealOuReferencia?.mod === 10) {
        bloco1 =
          codigo.substring(0, 11) + calculaMod10(codigo.substring(0, 11));
        bloco2 =
          codigo.substring(12, 23) + calculaMod10(codigo.substring(12, 23));
        bloco3 =
          codigo.substring(24, 35) + calculaMod10(codigo.substring(24, 35));
        bloco4 =
          codigo.substring(36, 47) + calculaMod10(codigo.substring(36, 47));
      } else if (identificacaoValorRealOuReferencia?.mod === 11) {
        bloco1 = codigo.substring(0, 11);
        bloco2 = codigo.substring(12, 23);
        bloco3 = codigo.substring(24, 35);
        bloco4 = codigo.substring(36, 47);

        const dv1 = parseInt(codigo.substring(11, 1), 10);
        const dv2 = parseInt(codigo.substring(23, 1), 10);
        const dv3 = parseInt(codigo.substring(35, 1), 10);
        const dv4 = parseInt(codigo.substring(47, 1), 10);

        const valid =
          calculaMod11(bloco1) === dv1 &&
          calculaMod11(bloco2) === dv2 &&
          calculaMod11(bloco3) === dv3 &&
          calculaMod11(bloco4) === dv4;

        return valid;
      }

      resultado = bloco1 + bloco2 + bloco3 + bloco4;
    }
  } else if (tipoCodigo === 'CODIGO_DE_BARRAS') {
    tipoBoleto = identificarTipoBoleto(codigo);
    if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
      const DV = calculaDVCodBarras(codigo, 4, 11);
      resultado = codigo.substring(0, 4) + DV + codigo.substring(5);
    } else {
      const identificacaoValorRealOuReferencia: any =
        identificarReferencia(codigo);

      resultado = codigo.split('');
      resultado.splice(3, 1);
      resultado = resultado.join('');

      const DV = calculaDVCodBarras(
        codigo,
        3,
        identificacaoValorRealOuReferencia.mod,
      );
      resultado = resultado.substring(0, 3) + DV + resultado.substring(3);
    }
  }

  return codigo === resultado;
}

/**
 * ## __`BOLETO COBRANÇA`__
 * ### __AS POSIÇÕES AQUI MENCIONADAS PARTEM DO NÚMERO 0 E NÃO DO 1, A FIM DE FACILITAR O ENTENDIMENTO LÓGICO__
 * ---------------------------------------------------------
 *
 * ### __TIPO:__ CÓDIGO DE BARRAS (44 POSIÇÕES NUMÉRICAS)
 *
 * ---------------------------------------------------------
 *
 * #### __EXEMPLO:__ 11123444455555555556666666666666666666666666
 *
 * Bloco | Posições | Definição
 * --- | --- | ---
 * __1__ | **0 a 2**  | `Código do Banco na Câmara de Compensação`
 * __2__ | **3 a 3**  | `Código da Moeda = 9 (Real)`
 * __3__ | **4 a 4**  | `Digito Verificador (DV) do código de Barras`
 * __4__ | **5 a 8**  | `Fator de Vencimento`
 * __5__ | **9 a 18**  | `Valor com 2 casas de centavos`
 * __6__ | **19 a 43**  | `Campo Livre (De uso da instituição bancária)`
 *
 * ---------------------------------------------------------
 *
 * ### __TIPO:__ LINHA DIGITÁVEL (47 POSIÇÕES NUMÉRICAS)
 *
 * ---------------------------------------------------------
 *
 * #### __EXEMPLO__: AAABC.CCCCX DDDDD.DDDDDY EEEEE.EEEEEZ K UUUUVVVVVVVVVV
 *
 * Campo | Posições linha dig. | Definição
 * --- | --- | ---
 * __A__ | **0 a 2** (0 a 2 do cód. barras)  | `Código do Banco na Câmara de compensação`
 * __B__ | **3 a 3** (3 a 3 do cód. barras)  | `Código da moeda`
 * __C__ | **4 a 8** (19 a 23 do cód. barras)  | `Campo Livre`
 * __X__ | **9 a 9**  | `Dígito verificador do Bloco 1 (Módulo 10)`
 * __D__ | **10 a 19** (24 a 33 do cód. barras)  | `Campo Livre`
 * __Y__ | **20 a 20**  | `Dígito verificador do Bloco 2 (Módulo 10)`
 * __E__ | **21 a 30** (24 a 43 do cód. barras)  | `Campo Livre`
 * __Z__ | **31 a 31**  | `Dígito verificador do Bloco 3 (Módulo 10)`
 * __K__ | **32 a 32** (4 a 4 do cód. barras)  | `Dígito verificador do código de barras`
 * __U__ | **33 a 36** (5 a 8 do cód. barras)  | `Fator de Vencimento`
 * __V__ | **37 a 43** (9 a 18 do cód. barras)  | `Valor`
 *
 * ## __`CONTA CONVÊNIO / ARRECADAÇÃO`__
 *
 * ---------------------------------------------------------
 *
 * ### __TIPO:__ CÓDIGO DE BARRAS (44 POSIÇÕES NUMÉRICAS)
 *
 * ---------------------------------------------------------
 *
 * #### __EXEMPLO__: 12345555555555566667777777777777777777777777
 *
 * Campo | Posições | Definição
 * --- | --- | ---
 * __1__ | **0 a 0**  | `"8" Identificação da Arrecadação/convênio`
 * __2__ | **1 a 1**  | `Identificação do segmento`
 * __3__ | **2 a 2**  | `Identificação do valor real ou referência`
 * __4__ | **3 a 3**  | `Dígito verificador geral (módulo 10 ou 11)`
 * __5__ | **4 a 14**  | `Valor efetivo ou valor referência`
 * __6__ | **15 a 18**  | `Identificação da empresa/órgão`
 * __7__ | **19 a 43**  | `Campo livre de utilização da empresa/órgão`
 *
 * ---------------------------------------------------------
 *
 * ### __TIPO:__ LINHA DIGITÁVEL (48 POSIÇÕES NUMÉRICAS)
 *
 * ---------------------------------------------------------
 *
 * #### __EXEMPLO__: ABCDEEEEEEE-W EEEEFFFFGGG-X GGGGGGGGGGG-Y GGGGGGGGGGG-Z
 *
 * Campo | Posições | Definição
 * --- | --- | ---
 * __A__ | **0 a 0**  | `"8" Identificação da Arrecadação/convênio`
 * __B__ | **1 a 1**  | `Identificação do segmento`
 * __C__ | **2 a 2**  | `Identificação do valor real ou referência`
 * __D__ | **3 a 3**  | `Dígito verificador geral (módulo 10 ou 11)`
 * __E__ | **4 a 14**  | `Valor efetivo ou valor referência`
 * __W__ | **11 a 11**  | `Dígito verificador do Bloco 1`
 * __F__ | **15 a 18**  | `Identificação da empresa/órgão`
 * __G__ | **19 a 43**  | `Campo livre de utilização da empresa/órgão`
 * __X__ | **23 a 23**  | `Dígito verificador do Bloco 2`
 * __Y__ | **35 a 35**  | `Dígito verificador do Bloco 3`
 * __Z__ | **47 a 47**  | `Dígito verificador do Bloco 4`
 */
export function validarBoleto(codigo: string) {
  const tipoCodigo = identificarTipoCodigo(codigo);

  let retorno: any = {};
  let errorValidacao = false;
  codigo = codigo.replace(/[^0-9]/g, '');

  /**
   * Boletos de cartão de crédito geralmente possuem 46 dígitos. É necessário adicionar mais um zero no final, para formar 47 caracteres
   * Alguns boletos de cartão de crédito do Itaú possuem 36 dígitos. É necessário acrescentar 11 zeros no final.
   */
  if (codigo.length === 36) {
    codigo += '00000000000';
  } else if (codigo.length === 46) {
    codigo += '0';
  }

  if (!errorValidacao) {
    if (![44, 46, 47, 48].includes(codigo.length)) {
      retorno.tipoCodigoInput = tipoCodigo;
      retorno.mensagem = `Código de Aceite do tipo Simplificado, preencha as informações manualmente.`;
      retorno.tipoAceite = 0;
      errorValidacao = true;
      return retorno;
    }

    if (
      codigo.startsWith('8') &&
      (codigo.length === 46 || codigo.length === 47)
    ) {
      retorno.mensagem =
        'Este tipo de boleto deve possuir um código de barras com 44 caracteres numéricos. Ou linha digitável de 48 caracteres numéricos.';
      errorValidacao = true;
      return retorno;
    }

    // Validar digito verificador
    if (!validarCodigoComDV(codigo, tipoCodigo)) {
      retorno.tipoCodigoInput = tipoCodigo;
      retorno.mensagem =
        'A validação do dígito verificador falhou. Tem certeza que inseriu a numeração correta?';
      errorValidacao = true;
    }
  }

  if (!errorValidacao) {
    retorno = retornoSucesso(codigo, tipoCodigo);
  }

  return retorno;
}

function retornoSucesso(codigo: string, tipoCodigo: string) {
  const retorno: any = {
    sucesso: true,
    mensagem: 'Boleto válido',
    tipoCodigoInput: tipoCodigo,
    tamanhoCodigo: codigo.length,
  };

  if (tipoCodigo === 'LINHA_DIGITAVEL') {
    retorno.tipoBoleto = identificarTipoBoleto(codigo);
    retorno.codigoBarras = linhaDigitavel2CodBarras(codigo);
    retorno.linhaDigitavel = codigo;
    retorno.tipoAceite = identificarTipoAceite(codigo, tipoCodigo);
    retorno.codigoBanco = itendificarBanco(codigo);
    retorno.vencimento = identificarData(codigo, tipoCodigo);
    retorno.valor = identificarValor(codigo, tipoCodigo);
  } else if (tipoCodigo === 'CODIGO_DE_BARRAS') {
    retorno.tipoBoleto = identificarTipoBoleto(codigo);
    retorno.codigoBarras = codigo;
    retorno.tipoAceite = identificarTipoAceite(codigo, tipoCodigo);
    retorno.codigoBanco = itendificarBanco(codigo);
    retorno.linhaDigitavel = codBarras2LinhaDigitavel(codigo, false);
    retorno.vencimento = identificarData(codigo, tipoCodigo);
    retorno.valor = identificarValor(codigo, tipoCodigo);
  }

  return retorno;
}
/**
 * Calcula o dígito verificador de uma numeração a partir do módulo 11
 *
 * -------------
 *
 * @param {string} x Numeração
 *
 * -------------
 *
 * @return {string} digito
 */
export function calculaMod11(x: string) {
  const sequencia = [4, 3, 2, 9, 8, 7, 6, 5];
  let digit = 0;
  let j = 0;
  let DAC = 0;

  for (let i = 0; i < x.length; i++) {
    const mult = sequencia[j];
    j++;
    j %= sequencia.length;

    digit += mult * parseInt(x.charAt(i), 10);
  }

  DAC = digit % 11;
  if (DAC === 0 || DAC === 1) return 0;
  if (DAC === 10) return 1;

  return 11 - DAC;
}

export function calculaMod10(numero: string) {
  numero = numero.replace(/\D/g, '');
  let i;
  let mult = 2;
  let soma = 0;
  let s = '';

  // Multiplicação pela sequência de [2,1,2,1...] e guarda os valores dos dígitos do produto em s
  for (i = numero.length - 1; i >= 0; i--) {
    s = mult * parseInt(numero.charAt(i), 10) + s;
    if (--mult < 1) {
      mult = 2;
    }
  }

  // Soma todos os valores dos dígitos do produto guardado
  for (i = 0; i < s.length; i++) {
    soma += parseInt(s.charAt(i), 10);
  }

  // Faz a divisão da soma dos valores dos dígitos por 10
  soma %= 10;

  // Seguindo a fórmula da FEBRABAN DAC = 10 - (Resto da divisão)
  if (soma !== 0) {
    soma = 10 - soma;
  }

  return soma;
}

export function calculaDVCodBarras(
  codigo: string,
  posicaoCodigo: number,
  mod: number,
) {
  codigo = codigo.replace(/[^0-9]/g, '');
  const codigoArray: string[] = codigo.split('');
  codigoArray.splice(posicaoCodigo, 1);
  codigo = codigoArray.join('');
  if (mod === 10) {
    return calculaMod10(codigo);
  }
  if (mod === 11) {
    return calculaMod11(codigo);
  }
}
export function codBarras2LinhaDigitavel(codigo: string, formatada: boolean) {
  codigo = codigo.replace(/[^0-9]/g, '');

  const tipoBoleto = identificarTipoBoleto(codigo);

  let resultado = '';

  if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
    const novaLinha =
      codigo.substring(0, 4) +
      codigo.substring(19, 19 + 25) +
      codigo.substring(4, 4 + 1) +
      codigo.substring(5, 5 + 14);

    const bloco1 =
      novaLinha.substring(0, 9) + calculaMod10(novaLinha.substring(0, 9));
    const bloco2 =
      novaLinha.substring(9, 9 + 10) +
      calculaMod10(novaLinha.substring(9, 9 + 10));
    const bloco3 =
      novaLinha.substring(19, 19 + 10) +
      calculaMod10(novaLinha.substring(19, 19 + 10));
    const bloco4 = novaLinha.substring(29);

    resultado = (bloco1 + bloco2 + bloco3 + bloco4).toString();
    if (formatada) {
      resultado = `${resultado.slice(0, 5)}.${resultado.slice(
        5,
        10,
      )} ${resultado.slice(10, 15)}.${resultado.slice(
        15,
        21,
      )} ${resultado.slice(21, 26)}.${resultado.slice(
        26,
        32,
      )} ${resultado.slice(32, 33)} ${resultado.slice(33)}`;
    }
  } else {
    const identificacaoValorRealOuReferencia: any =
      identificarReferencia(codigo);
    let bloco1 = '';
    let bloco2 = '';
    let bloco3 = '';
    let bloco4 = '';

    if (identificacaoValorRealOuReferencia.mod === 10) {
      bloco1 =
        codigo.substring(0, 0 + 11) + calculaMod10(codigo.substring(0, 0 + 11));
      bloco2 =
        codigo.substring(11, 11 + 11) +
        calculaMod10(codigo.substring(11, 11 + 11));
      bloco3 =
        codigo.substring(22, 22 + 11) +
        calculaMod10(codigo.substring(22, 22 + 11));
      bloco4 =
        codigo.substring(33, 33 + 11) +
        calculaMod10(codigo.substring(33, 33 + 11));
    } else if (identificacaoValorRealOuReferencia.mod === 11) {
      bloco1 =
        codigo.substring(0, 0 + 11) + calculaMod11(codigo.substring(0, 0 + 11));
      bloco2 =
        codigo.substring(11, 11 + 11) +
        calculaMod11(codigo.substring(11, 11 + 11));
      bloco3 =
        codigo.substring(22, 22 + 11) +
        calculaMod11(codigo.substring(22, 22 + 11));
      bloco4 =
        codigo.substring(33, 33 + 11) +
        calculaMod11(codigo.substring(33, 33 + 11));
    }

    resultado = bloco1 + bloco2 + bloco3 + bloco4;
  }

  return resultado;
}
export function linhaDigitavel2CodBarras(codigo: string) {
  codigo = codigo.replace(/[^0-9]/g, '');

  const tipoBoleto = identificarTipoBoleto(codigo);

  let resultado = '';
  let codigoArray: string[] = [];

  if (tipoBoleto === 'BANCO' || tipoBoleto === 'CARTAO_DE_CREDITO') {
    resultado =
      codigo.substring(0, 4) +
      codigo.substring(32, 33) +
      codigo.substring(33, 47) +
      codigo.substring(4, 9) +
      codigo.substring(10, 20) +
      codigo.substring(21, 31);
  } else {
    codigoArray = codigo.split('');
    codigoArray.splice(11, 1);
    codigoArray.splice(22, 1);
    codigoArray.splice(33, 1);
    codigoArray.splice(44, 1);
    codigo = codigoArray.join('');

    resultado = codigo;
  }

  return resultado;
}

function substringReplace(
  str: string,
  repl: string,
  inicio: number,
  tamanho: number,
) {
  if (inicio < 0) {
    inicio += str.length;
  }

  tamanho = tamanho !== undefined ? tamanho : str.length;
  if (tamanho < 0) {
    tamanho = tamanho + str.length - inicio;
  }

  return [
    str.slice(0, inicio),
    repl.substring(0, tamanho),
    repl.slice(tamanho),
    str.slice(inicio + tamanho),
  ].join('');
}
